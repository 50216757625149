import { Helmet } from "react-helmet";
import React from "react";

import settings from '../../config/settings';

export default class Commento extends React.Component {
    
    render() {
        const {
            isLocal = false,
        } = settings || {};
        const prod_url = "/static/scripts/comentario.js";
        const local_url = "https://comentario.merchvice.com/comentario.js";
        const url = isLocal ? local_url : prod_url;
        return (
            <React.Fragment>
                <comentario-comments></comentario-comments>
                <Helmet>
                    <script 
                        defer 
                        src={url}
                        />
                </Helmet>
            </React.Fragment>
        );
    }
}
