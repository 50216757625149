import isEmpty from 'lodash/isEmpty';
const sha256 = require('js-sha256');
import axios from 'axios';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { createUserWithIdp } from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/auth/CONFIRM_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

const hexToString = (hex) => {
  let str = '';
  for (let i = 0; i < hex.length; i += 2) {
    const hexValue = hex.substr(i, 2);
    const decimalValue = parseInt(hexValue, 16);
    str += String.fromCharCode(decimalValue);
  }
  return str;
};

const stringToHex = (str) => {
  let hex = '';
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    const hexValue = charCode.toString(16);

    // Pad with zeros to ensure two-digit representation
    hex += hexValue.padStart(2, '0');
  }
  return hex;
};

const hexToBytes = (hex) => {
  let bytes = [];
  for (let c = 0; c < hex.length; c += 2)
      bytes.push(parseInt(hex.substr(c, 2), 16));
  return bytes;
}

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const getSSOSessionToken = async (bearer_token) => {
  const commento_base_url = 'https://comentario.merchvice.com';
  const domain = 'merchvice-1e54f28c2d1e.herokuapp.com';
  const newtoken = {
    method: 'put',
    url: `${commento_base_url}/api/embed/auth/login/token`,
    data: {
      host: domain,
    },
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${bearer_token}`,
    },
  }
  const sessionToken = await axios(newtoken)
    .then((r1) => {
      const newSessionToken = r1?.data?.sessionToken
      return Promise.resolve(newSessionToken)
    })
    .catch((error) => {
      console.error(error)
    })
  console.log('new token', sessionToken);
  return sessionToken;
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const doSSOCallback = async (payload_hex, payload_hmac) => {
  const commento_base_url = 'https://comentario.merchvice.com';
  const auth_session_cookie = getCookie('_comentario_auth_session');
  console.log('auth_session_cookie', auth_session_cookie);
  const newtoken = {
    method: 'get',
    url: `${commento_base_url}/api/oauth/sso/callback?payload=${payload_hex}&hmac=${payload_hmac}`,
    headers: {
      'Cookie': auth_session_cookie,
    },
    withCredentials: true,
  }
  await axios(newtoken)
    .then((r1) => {
      console.log('doSSOCallback', r1);
    })
    .catch((error) => {
      console.error(error)
    })
  console.log('new token', sessionToken);
  return Promise.resolve();
}

const comentarioSSO = async (encoded_token, encoded_hmac, sdk) => {
  if (!encoded_token || !encoded_hmac) {
    return Promise.resolve();
  }
  const domain = 'merchvice-1e54f28c2d1e.herokuapp.com';
  const secret_key = hexToBytes('7c7e79574ecf22f249ad91a9545b9ac5db8bf9e3f61840236a139da87f89d54c');

  const token = hexToBytes(encoded_token);
  // const hmac = hexToBytes(encoded_hmac);
  const expected_hmac = sha256.hmac(secret_key, token);
  // console.log('encoded_hmac', encoded_hmac);
  // console.log('encoded_token', encoded_token);
  // console.log('expected_hmac', expected_hmac);
  // console.log('actual_mac', hmac);
  // console.log('expected_hmac_encoded', stringToHex(expected_mac));

  let user_id = null;
  let user_email = null;
  let user_name = null;
  let user_profile_image_url = null;

  await sdk.currentUser.show({
    include: ['profileImage'],
    'fields.image': [
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  }).then(sdk_res => {
    // console.log('sdk_res.data.data', sdk_res?.data?.data);
    // console.log('sdk_res.data', sdk_res?.data);
    const {
      data: sdk_res_data = {},
      included: included_data = [],
    } = sdk_res?.data || {};
    const {
      id,
      attributes,
    } = sdk_res_data || {};
    const {
      email,
      profile,
    } = attributes || {};
    const {
      displayName,
    } = profile || {};
    user_id = id?.uuid || null;
    user_email = email || null;
    user_name = displayName || null;

    const foundProfileImage = included_data.find((included) => included?.attributes?.variants?.['square-small2x']);
    if (foundProfileImage) {
      user_profile_image_url = foundProfileImage.attributes.variants['square-small2x'].url || null;
    }
  })
  .catch((err) => {
    console.log('err', err.message);
  });

  const vals = {
    token: encoded_token,
    email: user_email,
    name:  user_name,
    link:  `https://merchvice-1e54f28c2d1e.herokuapp.com/u/${user_id}`,
  };

  if (user_profile_image_url) {
    vals.photo = user_profile_image_url;
  }

  const vals_json = JSON.stringify(vals);

  // console.log('user_id', user_id);
  // console.log('user_email', user_email);
  // console.log('token', token);
  // console.log('hmac', hmac);

  const new_hmac = sha256.hmac(secret_key, vals_json);
  const payload_hex = stringToHex(vals_json);
  const sso_forward_url = `https://comentario.merchvice.com/api/oauth/sso/callback?payload=${payload_hex}&hmac=${new_hmac}`;
  // console.log('new_hmac', new_hmac, payload_hex, vals_json);
  // console.log('fwd2', sso_forward_url);
  // await doSSOCallback(payload_hex, new_hmac);
  window.open(sso_forward_url, 'targetWindow',
  `toolbar=no,
   location=no,
   status=no,
   menubar=no,
   scrollbars=yes,
   resizable=yes,
   width=50,
   height=50`);
  await delay(3000);
  // console.log('get sso session token');
  const session_token = await getSSOSessionToken(encoded_token);
  try {
    document.cookie = `comentario_auth_token=${session_token};max-age=604800;path=/`;
  } catch (err) {
    console.log('cookie error', err.message);
  }
  // window.location.replace(sso_forward_url);
  // window.parent.close();
  return Promise.resolve();
}

export const doComentarioSSO = (commento_token, commento_hmac) => (dispatch, getState, sdk) => {
  return comentarioSSO(commento_token, commento_hmac, sdk)
  .catch(e => {
    console.log('e', e.message);
  });
};

export const login = (username, password, commento_token, commento_hmac) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => dispatch(fetchCurrentUser()))
    .then(() => comentarioSSO(commento_token, commento_hmac, sdk))
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, firstName, lastName, ...rest } = params;

  const createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName }
    : { email, password, firstName, lastName, protectedData: { ...rest } };

  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  return sdk.currentUser
    .create(createUserParams)
    .then(() => dispatch(signupSuccess()))
    .then(() => dispatch(login(email, password)))
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());
  return createUserWithIdp(params)
    .then(res => {
      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(confirmError(storableError(e)));
    });
};
